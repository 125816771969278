import { DOMNode, domToReact, Element } from "html-react-parser";

import { getAnalytics } from "@/utils/analytics";
import HTMLElement from "@/components/reusable/webflow/HTMLElement";

/** Props for {@link TrackingElement} */
type TrackingElementProps = {
  /** The element to attach the onClick listener to */
  element: Element;
  /** Extra data to send with the tracking event */
  extraData?: object;
  /** The function to use to replace the element's children */
  replace: (node: DOMNode) => JSX.Element | undefined;
};

/**
 * Attaches an onClick event to the element that will trigger
 * a tracking event named with the `tracking-name` attribute,
 * sending the properties specified in the `tracking-data` attribute.
 *
 * @param props - Component props
 * @returns
 */
function TrackingElement({ element, extraData, replace }: TrackingElementProps) {
  const attribs = element.attribs || {};
  const trackingName = attribs["tracking-name"];

  function track() {
    if (!trackingName) return;
    try {
      const dataTrackingData = attribs["tracking-data"];
      const trackingData: object = dataTrackingData ? JSON.parse(dataTrackingData.replaceAll("'", '"')) : {};
      getAnalytics()?.track(trackingName, { ...trackingData, ...extraData });
    } catch {
      // Fail silently
    }
  }

  return (
    <HTMLElement element={element} onClick={() => track()}>
      {domToReact(element.childNodes, {
        replace,
      })}
    </HTMLElement>
  );
}

export default TrackingElement;
