import { PropsWithChildren, useEffect } from "react";

import useHasMounted from "@/utils/hooks/useHasMounted";
import { useGetPosthog } from "@/utils/analytics";

/** Props for {@link FeatureFlagOnly} */
interface Props {
  /** PostHog feature flag ID */
  flag: string;
  /** Optional PostHog feature flag variant */
  variant?: string;
  /**
   * Optional strategy to use for the fallback when the flag is not found in PostHog.
   *
   * - `default`: returns `fallback?.()` or null
   * - `keep-control`: renders `fallback?.()` or the children of variant "control"
   */
  noFlagStrategy?: "default" | "keep-control";
  /**
   * Optional method to choose which element
   * to render instead of null.
   */
  fallback?: (hasMounted: boolean, posthogLoading: boolean) => JSX.Element | undefined;
  /**
   * Optional method called once the
   * component has mounted.
   */
  onMount?: () => void;
}

/**
 * Renders children only once the component
 * has mounted and if the feature flag is active.
 *
 * @param props - Component props
 *
 * @returns children
 */
export default function FeatureFlagOnly({
  flag,
  variant,
  noFlagStrategy,
  fallback,
  onMount,
  children,
}: PropsWithChildren<Props>) {
  const { posthog, status } = useGetPosthog();
  const hasMounted = useHasMounted();

  useEffect(() => {
    if (hasMounted && onMount) onMount();
  }, [hasMounted, onMount]);

  const flagVariant = posthog?.getFeatureFlag(flag);
  const enabled = variant ? flagVariant === variant : flagVariant === true;
  if (hasMounted && enabled) return <>{children}</>;

  if (noFlagStrategy === "keep-control" && !flagVariant && variant === "control" && hasMounted) {
    return fallback?.(hasMounted, status === "loading") ?? <>{children}</>;
  }

  return fallback?.(hasMounted, status === "loading") ?? null;
}
