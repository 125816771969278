import { attributesToProps, DOMNode, domToReact, Element } from "html-react-parser";
import { i18n } from "next-i18next";

import Link from "@/components/hoc/CustomLink";
import translateDOMNode from "@/utils/webflow/client/translateDOMNode";
import TrackingElement from "@/components/reusable/webflow/TrackingElement";
import { getAnalytics } from "@/utils/analytics";
import FeatureFlagOnly from "@/components/hoc/FeatureFlagOnly";
import HTMLElement from "@/components/reusable/webflow/HTMLElement";

export const WEBFLOW_SLASH_PLACEHOLDER = "0pty0";

/** Determines if URL is internal or external */
function isUrlInternal(link: string) {
  if (
    !link ||
    link.indexOf(`https:`) === 0 ||
    link.indexOf(`#`) === 0 ||
    link.indexOf(`http`) === 0 ||
    link.indexOf(`://`) === 0 ||
    link.indexOf(`mailto:`) === 0
  ) {
    return false;
  }
  return true;
}

/**
 * Replaces DOMNode with NextJS Link when internal href
 *
 * @param node - DOM node to replace
 * @returns JSX.Element of the link
 */
function replace(node: DOMNode, parentReplace?: (node: DOMNode) => JSX.Element | undefined) {
  const element = node as Element;
  const attribs = element.attribs || {};

  // WARNING: This must be the first check
  if (attribs["data-flag-key"]) {
    const flag = attribs["data-flag-key"];
    const variant = attribs["data-flag-variant"];
    delete element.attribs["data-flag-key"];
    delete element.attribs["data-flag-variant"];
    return (
      <FeatureFlagOnly flag={flag} variant={variant} noFlagStrategy="keep-control">
        {domToReact([element], { replace: parentReplace ?? replace })}
      </FeatureFlagOnly>
    );
  }

  if (attribs["data-close-button"]) {
    const closeId = attribs["data-close-button"];

    return (
      <HTMLElement
        element={element}
        onClick={() => {
          // Get all elements with data attribute data-close-target set to the closeId
          const closeTargets = document.querySelectorAll(`[data-close-target="${closeId}"]`);
          closeTargets.forEach((target) => {
            (target as HTMLElement).style.display = "none";
          });
        }}
      >
        {domToReact(element.children, { replace: parentReplace ?? replace })}
      </HTMLElement>
    );
  }

  // Replace links with Next links
  if (element.name === `a` && isUrlInternal(attribs.href)) {
    const { href, ...props } = attribs;
    if (props.class) {
      props.className = props.class;
      delete props.class;
    }

    let url = href;
    let shallow = false;
    // TODO: The WF blog post pagination links are not working
    if (url.startsWith("?")) {
      // Handle webflow query param URLs (e.g. to change pages)
      if (typeof window !== "undefined") {
        url = `${window.location.pathname}${url}`;
        shallow = true;
      }
    }

    // NOTE: This is a hack to fix the Webflow blog post URLs
    if (url.startsWith("/press-post/") || url.startsWith("/press-tag/")) {
      // Remove -post -tag and leading slash
      url = url.replace("-post", "").replace("-tag", "").slice(1);
      const path = url.split("/")[1];
      const reconstructedHref = path.split(WEBFLOW_SLASH_PLACEHOLDER).join("/");
      url = `/press/${reconstructedHref}`;
    } else if (url.startsWith("/post/") || url.startsWith("/wf-tag/")) {
      // Remove "wf-"" and prepend "blog" to url
      url = `/blog${url.replace("wf-", "")}`;
    }

    let text: string | undefined;
    // NOTE: Directly translate text of anchor tags if needed
    if (attribs.i18nkey && i18n?.t) {
      const { i18nkey, i18nnamespace } = attribs;
      text = i18n.t(i18nkey, { ns: i18nnamespace });
    }

    let onClick: (() => void) | undefined;
    // NOTE: Add tracking onClick event if needed
    if (attribs["tracking-name"]) {
      onClick = () => {
        try {
          const attribsData = attribs["tracking-data"];
          const data: object = attribsData ? JSON.parse(attribsData.replaceAll("'", '"')) : {};
          getAnalytics()?.track(attribs["tracking-name"], { ...data });
        } catch {
          // Fail silently
        }
      };
    }

    return (
      <Link href={url} shallow={shallow}>
        <a {...attributesToProps(props)} onClick={onClick}>
          {text ?? domToReact(element.children, parseOptions)}
        </a>
      </Link>
    );
  }

  if (attribs["tracking-name"]) {
    return <TrackingElement element={element} replace={parentReplace ?? replace} />;
  }

  return translateDOMNode(node);
}

/**
 * Replaces certain DOMNodes. Used for <head> tag
 *
 * @param node - DOM node to replace
 * @returns JSX.Element | null
 */
function replaceHEADNode(node: DOMNode) {
  const element = node as Element;
  const attribs = element.attribs || {};

  if (element.tagName === "base") {
    return null;
  } else if (element.tagName === "meta" && attribs.content?.includes("noindex")) {
    return null;
  }

  return undefined;
}

export const parseOptions = { replace };
export const parseHEADOptions = { replace: replaceHEADNode };
