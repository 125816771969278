import { attributesToProps, Element } from "html-react-parser";
import { forwardRef, PropsWithChildren } from "react";

interface Props {
  element: Element;
  onClick?: (event: React.MouseEvent) => void;
}

// NOTE: Disabled any to not have to type all the HTML elements
//       This is not a problem since we are forwarding a ref of type HTMLElement
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function _HTMLElement({ element, onClick, children }: PropsWithChildren<Props>, ref: React.Ref<any>) {
  const attribs = element.attribs || {};
  const props = attributesToProps(attribs);

  switch (element.tagName) {
    case "a":
      return (
        <a ref={ref} onClick={onClick} {...props}>
          {children}
        </a>
      );
    case "abbr":
      return (
        <abbr ref={ref} onClick={onClick} {...props}>
          {children}
        </abbr>
      );
    case "address":
      return (
        <address ref={ref} onClick={onClick} {...props}>
          {children}
        </address>
      );
    case "area":
      return (
        <area ref={ref} onClick={onClick} {...props}>
          {children}
        </area>
      );
    case "article":
      return (
        <article ref={ref} onClick={onClick} {...props}>
          {children}
        </article>
      );
    case "aside":
      return (
        <aside ref={ref} onClick={onClick} {...props}>
          {children}
        </aside>
      );
    case "audio":
      return (
        <audio ref={ref} onClick={onClick} {...props}>
          {children}
        </audio>
      );
    case "b":
      return (
        <b ref={ref} onClick={onClick} {...props}>
          {children}
        </b>
      );
    case "base":
      return (
        <base ref={ref} onClick={onClick} {...props}>
          {children}
        </base>
      );
    case "bdi":
      return (
        <bdi ref={ref} onClick={onClick} {...props}>
          {children}
        </bdi>
      );
    case "bdo":
      return (
        <bdo ref={ref} onClick={onClick} {...props}>
          {children}
        </bdo>
      );
    case "blockquote":
      return (
        <blockquote ref={ref} onClick={onClick} {...props}>
          {children}
        </blockquote>
      );
    case "body":
      return (
        <body ref={ref} onClick={onClick} {...props}>
          {children}
        </body>
      );
    case "br":
      return (
        <br ref={ref} onClick={onClick} {...props}>
          {children}
        </br>
      );
    case "button":
      return (
        <button ref={ref} onClick={onClick} {...props}>
          {children}
        </button>
      );
    case "canvas":
      return (
        <canvas ref={ref} onClick={onClick} {...props}>
          {children}
        </canvas>
      );
    case "caption":
      return (
        <caption ref={ref} onClick={onClick} {...props}>
          {children}
        </caption>
      );
    case "cite":
      return (
        <cite ref={ref} onClick={onClick} {...props}>
          {children}
        </cite>
      );
    case "code":
      return (
        <code ref={ref} onClick={onClick} {...props}>
          {children}
        </code>
      );
    case "col":
      return (
        <col ref={ref} onClick={onClick} {...props}>
          {children}
        </col>
      );
    case "colgroup":
      return (
        <colgroup ref={ref} onClick={onClick} {...props}>
          {children}
        </colgroup>
      );
    case "datalist":
      return (
        <datalist ref={ref} onClick={onClick} {...props}>
          {children}
        </datalist>
      );
    case "dd":
      return (
        <dd ref={ref} onClick={onClick} {...props}>
          {children}
        </dd>
      );
    case "del":
      return (
        <del ref={ref} onClick={onClick} {...props}>
          {children}
        </del>
      );
    case "details":
      return (
        <details ref={ref} onClick={onClick} {...props}>
          {children}
        </details>
      );
    case "dfn":
      return (
        <dfn ref={ref} onClick={onClick} {...props}>
          {children}
        </dfn>
      );
    case "div":
      return (
        <div ref={ref} onClick={onClick} {...props}>
          {children}
        </div>
      );
    case "dl":
      return (
        <dl ref={ref} onClick={onClick} {...props}>
          {children}
        </dl>
      );
    case "dt":
      return (
        <dt ref={ref} onClick={onClick} {...props}>
          {children}
        </dt>
      );
    case "em":
      return (
        <em ref={ref} onClick={onClick} {...props}>
          {children}
        </em>
      );
    case "embed":
      return (
        <embed ref={ref} onClick={onClick} {...props}>
          {children}
        </embed>
      );
    case "fieldset":
      return (
        <fieldset ref={ref} onClick={onClick} {...props}>
          {children}
        </fieldset>
      );
    case "figcaption":
      return (
        <figcaption ref={ref} onClick={onClick} {...props}>
          {children}
        </figcaption>
      );
    case "figure":
      return (
        <figure ref={ref} onClick={onClick} {...props}>
          {children}
        </figure>
      );
    case "footer":
      return (
        <footer ref={ref} onClick={onClick} {...props}>
          {children}
        </footer>
      );
    case "form":
      return (
        <form ref={ref} onClick={onClick} {...props}>
          {children}
        </form>
      );
    case "h1":
      return (
        <h1 ref={ref} onClick={onClick} {...props}>
          {children}
        </h1>
      );
    case "h2":
      return (
        <h2 ref={ref} onClick={onClick} {...props}>
          {children}
        </h2>
      );
    case "h3":
      return (
        <h3 ref={ref} onClick={onClick} {...props}>
          {children}
        </h3>
      );
    case "h4":
      return (
        <h4 ref={ref} onClick={onClick} {...props}>
          {children}
        </h4>
      );
    case "h5":
      return (
        <h5 ref={ref} onClick={onClick} {...props}>
          {children}
        </h5>
      );
    case "h6":
      return (
        <h6 ref={ref} onClick={onClick} {...props}>
          {children}
        </h6>
      );
    case "header":
      return (
        <header ref={ref} onClick={onClick} {...props}>
          {children}
        </header>
      );
    case "hgroup":
      return (
        <hgroup ref={ref} onClick={onClick} {...props}>
          {children}
        </hgroup>
      );
    case "hr":
      return (
        <hr ref={ref} onClick={onClick} {...props}>
          {children}
        </hr>
      );
    case "html":
      return (
        <html ref={ref} onClick={onClick} {...props}>
          {children}
        </html>
      );
    case "i":
      return (
        <i ref={ref} onClick={onClick} {...props}>
          {children}
        </i>
      );
    case "iframe":
      return (
        <iframe ref={ref} onClick={onClick} {...props}>
          {children}
        </iframe>
      );
    case "img":
      return (
        <img ref={ref} onClick={onClick} alt="" {...props}>
          {children}
        </img>
      );
    case "input":
      return (
        <input ref={ref} onClick={onClick} {...props}>
          {children}
        </input>
      );
    case "ins":
      return (
        <ins ref={ref} onClick={onClick} {...props}>
          {children}
        </ins>
      );
    case "kbd":
      return (
        <kbd ref={ref} onClick={onClick} {...props}>
          {children}
        </kbd>
      );
    case "keygen":
      return (
        <keygen ref={ref} onClick={onClick} {...props}>
          {children}
        </keygen>
      );
    case "label":
      return (
        <label ref={ref} onClick={onClick} {...props}>
          {children}
        </label>
      );
    case "legend":
      return (
        <legend ref={ref} onClick={onClick} {...props}>
          {children}
        </legend>
      );
    case "li":
      return (
        <li ref={ref} onClick={onClick} {...props}>
          {children}
        </li>
      );
    case "link":
      return (
        <link ref={ref} onClick={onClick} {...props}>
          {children}
        </link>
      );
    case "map":
      return (
        <map ref={ref} onClick={onClick} {...props}>
          {children}
        </map>
      );
    case "mark":
      return (
        <mark ref={ref} onClick={onClick} {...props}>
          {children}
        </mark>
      );
    case "menu":
      return (
        <menu ref={ref} onClick={onClick} {...props}>
          {children}
        </menu>
      );
    case "meta":
      return (
        <meta ref={ref} onClick={onClick} {...props}>
          {children}
        </meta>
      );
    case "meter":
      return (
        <meter ref={ref} onClick={onClick} {...props}>
          {children}
        </meter>
      );
    case "nav":
      return (
        <nav ref={ref} onClick={onClick} {...props}>
          {children}
        </nav>
      );
    case "noscript":
      return (
        <noscript ref={ref} onClick={onClick} {...props}>
          {children}
        </noscript>
      );
    case "object":
      return (
        <object ref={ref} onClick={onClick} {...props}>
          {children}
        </object>
      );
    case "ol":
      return (
        <ol ref={ref} onClick={onClick} {...props}>
          {children}
        </ol>
      );
    case "optgroup":
      return (
        <optgroup ref={ref} onClick={onClick} {...props}>
          {children}
        </optgroup>
      );
    case "option":
      return (
        <option ref={ref} onClick={onClick} {...props}>
          {children}
        </option>
      );
    case "output":
      return (
        <output ref={ref} onClick={onClick} {...props}>
          {children}
        </output>
      );
    case "p":
      return (
        <p ref={ref} onClick={onClick} {...props}>
          {children}
        </p>
      );
    case "param":
      return (
        <param ref={ref} onClick={onClick} {...props}>
          {children}
        </param>
      );
    case "pre":
      return (
        <pre ref={ref} onClick={onClick} {...props}>
          {children}
        </pre>
      );
    case "progress":
      return (
        <progress ref={ref} onClick={onClick} {...props}>
          {children}
        </progress>
      );
    case "q":
      return (
        <q ref={ref} onClick={onClick} {...props}>
          {children}
        </q>
      );
    case "rp":
      return (
        <rp ref={ref} onClick={onClick} {...props}>
          {children}
        </rp>
      );
    case "rt":
      return (
        <rt ref={ref} onClick={onClick} {...props}>
          {children}
        </rt>
      );
    case "ruby":
      return (
        <ruby ref={ref} onClick={onClick} {...props}>
          {children}
        </ruby>
      );
    case "s":
      return (
        <s ref={ref} onClick={onClick} {...props}>
          {children}
        </s>
      );
    case "samp":
      return (
        <samp ref={ref} onClick={onClick} {...props}>
          {children}
        </samp>
      );
    case "script":
      return (
        <script ref={ref} onClick={onClick} {...props}>
          {children}
        </script>
      );
    case "section":
      return (
        <section ref={ref} onClick={onClick} {...props}>
          {children}
        </section>
      );
    case "select":
      return (
        <select ref={ref} onClick={onClick} {...props}>
          {children}
        </select>
      );
    case "small":
      return (
        <small ref={ref} onClick={onClick} {...props}>
          {children}
        </small>
      );
    case "source":
      return (
        <source ref={ref} onClick={onClick} {...props}>
          {children}
        </source>
      );
    case "span":
      return (
        <span ref={ref} onClick={onClick} {...props}>
          {children}
        </span>
      );
    case "strong":
      return (
        <strong ref={ref} onClick={onClick} {...props}>
          {children}
        </strong>
      );
    case "style":
      return (
        <style ref={ref} onClick={onClick} {...props}>
          {children}
        </style>
      );
    case "sub":
      return (
        <sub ref={ref} onClick={onClick} {...props}>
          {children}
        </sub>
      );
    case "summary":
      return (
        <summary ref={ref} onClick={onClick} {...props}>
          {children}
        </summary>
      );
    case "sup":
      return (
        <sup ref={ref} onClick={onClick} {...props}>
          {children}
        </sup>
      );
    case "table":
      return (
        <table ref={ref} onClick={onClick} {...props}>
          {children}
        </table>
      );
    case "tbody":
      return (
        <tbody ref={ref} onClick={onClick} {...props}>
          {children}
        </tbody>
      );
    case "td":
      return (
        <td ref={ref} onClick={onClick} {...props}>
          {children}
        </td>
      );
    case "textarea":
      return (
        <textarea ref={ref} onClick={onClick} {...props}>
          {children}
        </textarea>
      );
    case "tfoot":
      return (
        <tfoot ref={ref} onClick={onClick} {...props}>
          {children}
        </tfoot>
      );
    case "th":
      return (
        <th ref={ref} onClick={onClick} {...props}>
          {children}
        </th>
      );
    case "thead":
      return (
        <thead ref={ref} onClick={onClick} {...props}>
          {children}
        </thead>
      );
    case "time":
      return (
        <time ref={ref} onClick={onClick} {...props}>
          {children}
        </time>
      );
    case "title":
      return (
        <title ref={ref} onClick={onClick} {...props}>
          {children}
        </title>
      );
    case "tr":
      return (
        <tr ref={ref} onClick={onClick} {...props}>
          {children}
        </tr>
      );
    case "track":
      return (
        <track ref={ref} onClick={onClick} {...props}>
          {children}
        </track>
      );
    case "u":
      return (
        <u ref={ref} onClick={onClick} {...props}>
          {children}
        </u>
      );
    case "ul":
      return (
        <ul ref={ref} onClick={onClick} {...props}>
          {children}
        </ul>
      );
    case "var":
      return (
        <var ref={ref} onClick={onClick} {...props}>
          {children}
        </var>
      );
    case "video":
      return (
        <video ref={ref} onClick={onClick} {...props}>
          {children}
        </video>
      );
    case "wbr":
      return (
        <wbr ref={ref} onClick={onClick} {...props}>
          {children}
        </wbr>
      );

    default:
      return (
        <div ref={ref} onClick={onClick} {...props}>
          {children}
        </div>
      );
  }
}

const HTMLElement = forwardRef<HTMLElement, PropsWithChildren<Props>>(_HTMLElement);
export default HTMLElement;
