import { useEffect } from "react";
import { WebflowProps } from "@/utils/webflow/server";

declare global {
  interface Window {
    Webflow: unknown;
  }
}

export default function useWebflowScripts({
  webflowScriptUrls: urls,
  webflowInlineScripts: inlineScripts,
  htmlElementAttribs: attribs,
  bodyContent,
}: WebflowProps) {
  useEffect(() => {
    if (!attribs) return;

    for (const [name, value] of Object.entries(attribs)) {
      document.documentElement.setAttribute(name, value);
    }
  }, [attribs]);

  useEffect(() => {
    async function loadUrls() {
      if (bodyContent && urls) {
        for (const url of urls) {
          await fetch(url)
            .then((res) => res.text())
            // eslint-disable-next-line no-eval
            .then((script) => eval(script))
            .catch(console.error);
        }
      }
    }

    loadUrls();

    return () => {
      // WARNING: Required to not break script on subsequent page loads
      delete window.Webflow;
    };
  }, [bodyContent, urls]);

  useEffect(() => {
    if (!bodyContent) return;

    if (inlineScripts) {
      for (const script of inlineScripts) {
        // eslint-disable-next-line no-eval
        eval(script);
      }
    }
  }, [inlineScripts, bodyContent]);
}
