import { DOMNode, Element } from "html-react-parser";
import { i18n } from "next-i18next";

import HTMLElement from "@/components/reusable/webflow/HTMLElement";

export default function translateDOMNode(node: DOMNode) {
  const element = node as Element;
  const attribs = element.attribs || {};

  if (attribs.i18nkey && i18n?.t) {
    const { i18nkey, i18nnamespace } = attribs;

    const text = i18n.t(i18nkey, { ns: i18nnamespace });
    return <HTMLElement element={element}>{text}</HTMLElement>;
  }
  return undefined;
}
