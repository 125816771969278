import { useEffect, useRef } from "react";
import { useUpdate } from "react-use";

/**
 * Uses the useEffect hook to only set the
 * hasMounted ref to true once the component has mounted.
 *
 * @remarks Very useful to run code only after the
 * component has mounted (and avoid rehydration issues
 * between SSR and CSR).
 *
 * @returns true if the component has mounted,
 * false otherwise
 */
export default function useHasMounted() {
  const hasMounted = useRef(false);
  const update = useUpdate();

  useEffect(() => {
    if (hasMounted.current === false) {
      hasMounted.current = true;
      update();
    }
  }, [update]);

  return hasMounted.current;
}
